<template>
    <ul class="list-unstyled pl-100">
        <li>
            <ds-link to="/molecules/accordion"> Accordion </ds-link>
        </li>
        <li>
            <ds-link to="/molecules/badge"> Badge </ds-link>
        </li>
        <li>
            <ds-link to="/molecules/breadcrumbs"> Breadcrumbs </ds-link>
        </li>
        <li>
            <ds-link to="/molecules/button"> Button </ds-link>
        </li>
        <li>
            <ds-link to="/molecules/card"> Card </ds-link>
        </li>
        <li>
            <ds-link to="/molecules/checkbox"> Checkbox </ds-link>
        </li>
        <li>
            <ds-link to="/molecules/collapse"> Collapse </ds-link>
        </li>
        <li>
            <ds-link to="/molecules/data-table"> Data table </ds-link>
        </li>
        <li>
            <ds-link to="/molecules/data-table-simple"> Data table simple </ds-link>
        </li>
        <li>
            <ds-link to="/molecules/file-input"> File input </ds-link>
        </li>
        <li>
            <ds-link to="/molecules/form-message"> Form message </ds-link>
        </li>
        <li>
            <ds-link to="/molecules/modal"> Modal </ds-link>
        </li>
        <li>
            <ds-link to="/molecules/pagination"> Pagination </ds-link>
        </li>
        <li>
            <ds-link to="/molecules/popover"> Popover </ds-link>
        </li>
        <li>
            <ds-link to="/molecules/progress"> Progress </ds-link>
        </li>
        <li>
            <ds-link to="/molecules/progress-circle"> Progress circle </ds-link>
        </li>
        <li>
            <ds-link to="/molecules/radio-button"> Radio button </ds-link>
        </li>
        <li>
            <ds-link to="/molecules/radio-cards"> Radio cards </ds-link>
        </li>
        <li>
            <ds-link to="/molecules/rating"> Rating </ds-link>
        </li>
        <li>
            <ds-link to="/molecules/skeleton"> Skeleton </ds-link>
        </li>
        <li>
            <ds-link to="/molecules/slider"> Slider </ds-link>
        </li>
        <li>
            <ds-link to="/molecules/support"> Support </ds-link>
        </li>
        <li>
            <ds-link to="/molecules/tabs"> Tabs </ds-link>
        </li>
        <li>
            <ds-link to="/molecules/textarea"> Textarea </ds-link>
        </li>
        <li>
            <ds-link to="/molecules/text-input"> Text input </ds-link>
        </li>
        <li>
            <ds-link to="/molecules/verification-code"> Verification code </ds-link>
        </li>
        <li>
            <ds-link to="/molecules/video"> Video </ds-link>
        </li>
        <li>
            <ds-link to="/molecules/view-more"> View more </ds-link>
        </li>
    </ul>
</template>
